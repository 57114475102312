
::v-deep {
    .v-calendar-weekly__head-weekday {
        padding: 0.5rem;
        font-size: 0.75rem;
        border-bottom: 1px solid #e0e0e0;
    }
    .v-calendar-weekly__day,
    .v-calendar-weekly__head-weekday {
        margin-right: 0px !important;
    }
}
