
::v-deep {
    .v-calendar-daily__day-container {
        margin-top: -12px;
    }
    .v-calendar-daily__scroll-area {
        overflow-y: auto;
    }
    .v-calendar-daily__interval:last-of-type {
        border-bottom: 1px solid #e0e0e0;
    }
}
